<template>
  <div>
    <!-- 顶部 -->
    <Head />
    <div class="cente_1">
      <img style="width:100%" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/b085a202405241518443920.png" alt />
      <div class="content_1">
        <div class="content_us">核心项目</div>
        <div class="content_yu">CORE PROJECTS</div>
      </div>
    </div>
    <!-- 企业介绍 -->
    <div class="enterprise">
      <img style="width:100%" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/2ee9f202405241519587490.jpg" alt />
      <div class="enterprise_box">
        <div class="enterprise_box_tit">项目介绍</div>
        <div class="enterprise_box_tit01">PROJECT INTRODUCTION</div>
        <div class="enterprise_box_tit02">
          <span class="enterprise_box_tit02_tit">
            橘色盒子酒店客房情趣用品
            <br />无人售货机
          </span>
        </div>
        <div class="enterprise_box_tit02_content">
          <div class="enterprise_box_tit02_box">
            <div class="enterprise_box_color_b">
              为酒店的入住客人解决场景内使用安全套及情趣用品的
              <br />及时需求
            </div>
          </div>
        </div>
        <div class="enterprise_boxit" >
          <div class="enterprise_box_tit03">
            <img
              class="enterprise_ig"
              src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/a5091202405231004132594.png"
              alt
            />
            <div>场景化</div>
          </div>
          <div class="enterprise_box_tit03">
            <img
              class="enterprise_ig"
              src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/308dc202405231016218191.png"
              alt
            />
            <div>及时性</div>
          </div>
          <div class="enterprise_box_tit03">
            <img
              class="enterprise_ig"
              src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/04bcb202405231016544108.png"
              alt
            />
            <div>隐私性</div>
          </div>
        </div>
      </div>
      <div>
        <div class="enterprise_imgS">
          <img
            v-if="currentPage === 1"
            src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/69737202405241421531910.png"
            alt
          />
          <img
            v-if="currentPage === 1"
            src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/f2835202405241423042296.png"
            alt
          />
        </div>
        <div class="enterprise_imgS">
          <img
            v-if="currentPage === 2"
            src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/1a08320240521144857167.jpg"
            alt
          />
          <img
            v-if="currentPage === 2"
            src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/d1159202405211449375861.jpg"
            alt
          />
        </div>
        <div class="enterprise_imgS">
          <img
            v-if="currentPage === 3"
            src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/36079202405241425009543.png"
            alt
          />
          <img
            v-if="currentPage === 3"
            src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/6bcae202405241426274131.png"
            alt
          />
        </div>
        <div>
          <div class="enterprise_imgS_click">
            <img
              @click="previousPage"
              :src="currentPage === 1 ? 'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/3e910202405231044532444.png' : 'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/7fffe202405231049052127.png'"
              alt
            />
            <div
              @click="changePage(1)"
              :style="{ color: currentPage === 1 ? 'rgba(255, 119, 51, 1)' : 'black', fontWeight: currentPage === 1 ? 'bold' : 'normal' }"
            >01</div>
            <div
              @click="changePage(2)"
              :style="{ color: currentPage === 2 ? 'rgba(255, 119, 51, 1)' : 'black', fontWeight: currentPage === 2 ? 'bold' : 'normal' }"
            >02</div>
            <div
              @click="changePage(3)"
              :style="{ color: currentPage === 3 ? 'rgba(255, 119, 51, 1)' : 'black', fontWeight: currentPage === 3 ? 'bold' : 'normal' }"
            >03</div>
            <img
              @click="nextPage"
              :src="currentPage === 3 ? 'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/1ac3b202405231046147468.png' : 'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/d4e35202405231049348128.png'"
              alt
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 战略规划 -->
    <div class="strategy">
      <img style="width:100%" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/61d6d202405241520412691.png" alt />
      <div class="strategy_box">
        <div class="strategy_box_1">战略规划</div>
        <div class="strategy_box_2">STRATEGIC PLAnNNING</div>
        <div class="strategy_box_3">橘色盒子2024年战略规划</div>
      </div>
      <div class="strategy_box_content">
        <div>
            <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/2a8e5202405231136396045.png" alt="">
            <div class="strategy_box_content1">开设城市直营分公司</div>
            <div class="strategy_box_content2">100个</div>
        </div>
      </div>
      <div class="strategy_box_content_2">
        <div>
            <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/05bf3202405231142257011.png" alt="">
            <div class="strategy_box_content1">服务酒店数量</div>
            <div class="strategy_box_content2">超3万家</div>
        </div>
      </div>
      <div class="strategy_box_content_3">
        <div>
            <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/95824202405231143074342.png" alt="">
            <div class="strategy_box_content1">服务客房数量</div>
            <div class="strategy_box_content2">突破100万间</div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="hellow_img5_content">
      <div class="hellow_img5_01">
        <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/a512320240528094722275.png" alt />
          <div style="margin-left:10px">服务热线</div>
        </div>
        <div class="hellow_img5_02">400-023-0001</div>
        <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/060ed202405280946206103.png" alt />
          <div style="margin-left:10px">公司地址</div>
        </div>
        <div style="width:300px;text-align: left;margin-top:20px">重庆市江北区北滨一路186号珠江国际A1写字楼橘色盒子总部13层</div>
      </div>
      <div class="hellow_img5_mido">
        <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/7c43d20240528094657182.png" alt />
          <div style="margin-left:10px">企业邮箱</div>
        </div>
        <div style="width:300px;text-align: left;margin-top:20px">bd@jusehezi.com</div>
        <div style="margin-top:60px">
          <div class="hellow_img5_03">
            <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/30426202405280940261584.png" alt />
            <div style="margin-left:10px">网址</div>
          </div>
          <div style="width:300px;text-align: left;margin-top:20px">www.jusehezi.com</div>
        </div>
      </div>
      <div class="hellow_img5_GF">
        <img  src="@/assets/photo_17.png" alt />
        <div style="margin-top:10px">【橘色盒子官方】</div>
      </div>
      <div class="About_m">
        <div class="abot_me" @click="toLink2('/Aboutme')">关于我们</div>
        <div class="abot_me" @click="toLink3('/Heart')">核心项目</div>
        <div class="abot_me" @click="toLink4('/Industry')">商业模式</div>
        <div class="abot_me" @click="toLink5('/future')">企业未来</div>
      </div>
      <div class="hellow_img5_footer">© 2019-2024 橘色盒子(重庆橘色盒子科技有限公司)版权所有 - <a class="a" href="https://beian.miit.gov.cn/"
                            target="_blank"
                            >渝ICP备19012992号-1</a></div>
    </div>
  </div>
</template>

<script>
import Head from "./components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {
      currentPage: 1
    };
  },

mounted() {
    let lastScrollTop = 0;
window.addEventListener("scroll", function() {
  let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
  if (currentScroll > lastScrollTop) {
    console.log(currentScroll > lastScrollTop,"currentScroll > lastScrollTop")
    // 鼠标向下滚动
    // 在这里触发你的代码逻辑
    const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5 // 元素可见性的阈值，这里设置为50%
  };

  const callback = (entries, observer) => {
    entries.forEach(entry => {
     
      if (entry.isIntersecting) {
        // 元素进入可视区域
        entry.target.style.transition = "opacity 0.5s, transform 0.5s"; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 1; // 设置元素的透明度为1，实现淡入效果
        entry.target.style.transform = "translateY(0)"; // 从下往上出现
      } else {
        // 元素离开可视区域
        entry.target.style.transition = "opacity 0.5s, transform 0.5s"; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 0; // 设置元素的透明度为0，实现淡出效果
        entry.target.style.transform = "translateY(20px)"; // 元素向上移动隐藏
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  const targets = document.querySelectorAll('.enterprise_box_tit,.enterprise_box_tit01,.enterprise_box_tit02_tit,.enterprise_box_tit02_content,.enterprise_boxit,.strategy_box_1,.strategy_box_2,.strategy_box_3,.strategy_box_content,.strategy_box_content2,.strategy_box_content3,.strategy_box_content1,.strategy_box_content_2,.strategy_box_content_3,.enterprise_box_tit02'); // 选择你想观察的标签的类名或选择器

  targets.forEach(target => observer.observe(target));

  } else {
    // 鼠标向上滚动
    // 在这里不触发你的代码逻辑
    console.log(123)
    const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5 // 元素可见性的阈值，这里设置为50%
  };

  const callback = (entries, observer) => {
    entries.forEach(entry => {
     
      if (entry.isIntersecting) {
        // 元素进入可视区域
        entry.target.style.transition = " "; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 1; // 设置元素的透明度为1，实现淡入效果
        entry.target.style.transform = ""; // 从下往上出现
      } else {
        // 元素离开可视区域
        entry.target.style.transition = ","; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 1; // 设置元素的透明度为0，实现淡出效果
        entry.target.style.transform = ""; // 元素向上移动隐藏
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  const targets = document.querySelectorAll('.enterprise_box_tit,.enterprise_box_tit01,.enterprise_box_tit02_tit,.enterprise_box_tit02_content,.enterprise_boxit,.strategy_box_1,.strategy_box_2,.strategy_box_3,.strategy_box_content,.strategy_box_content2,.strategy_box_content3,.strategy_box_content1,.strategy_box_content_2,.strategy_box_content_3,.enterprise_box_tit02'); // 选择你想观察的标签的类名或选择器

  targets.forEach(target => observer.observe(target));

  }
  lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // 当滚动到顶部时重置滚动位置
}, false);

 
},
  computed: {
    canGoForward() {
      return this.currentPage < 3;
    },
    canGoBackward() {
      return this.currentPage > 1;
    }
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
    },
    previousPage() {
      if (this.canGoBackward) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.canGoForward) {
        this.currentPage++;
      }
    },
    toLink2() {
            this.$router.push({ path: '/Aboutme' })
        },
        toLink3() {
            this.$router.push({ path: '/Heart' })
        },
        toLink4() {
            this.$router.push({ path: '/Industry' })
        },
        toLink5() {
            this.$router.push({ path: '/future' })
        },
  }
};
</script>

<style>
.a{
  color: silver;
}
.a:hover {
    color: orange;
}
.hellow_img5_03 img{
  width: 28px;
  height: 28px;
}
.hellow_img5_GF img{
width: 157px;
height: 157px;
}
.strategy_box_content1{
    font-size: 20px;
font-weight: 500;
color: #fff;
margin-top: 60px;
}
.strategy_box_content2{
    font-size: 40px;
font-weight: 900;
color: #fff;
margin-top: 20px;

}
.strategy_box_content img{
    width: 96px;
    height: 96px;
    margin-top: 40px;
}
.strategy_box_content_2 img{
    width: 96px;
    height: 96px;
    margin-top: 40px;
}
.strategy_box_content_3 img{
    width: 96px;
    height: 96px;
    margin-top: 40px;
}
.strategy_box_content {
  left: 347px;
  top: 2023px;
  width: 326px;
  height: 364px;
  opacity: 1;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 15%;
  top: 50%;
}
.strategy_box_content:hover {
  background-color: rgba(255, 119, 51, 1);
}
.strategy_box_content_2:hover {
  background-color: rgba(255, 119, 51, 1);
}
.strategy_box_content_3:hover {
  background-color: rgba(255, 119, 51, 1);
}
.strategy_box_content_2 {
  left: 347px;
  top: 2023px;
  width: 326px;
  height: 364px;
  opacity: 1;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 40%;
  top: 50%;
}
.strategy_box_content_3 {
  left: 347px;
  top: 2023px;
  width: 326px;
  height: 364px;
  opacity: 1;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 65%;
  top: 50%;
}

.strategy_box_3 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  margin-top: 80px;
}
.strategy_box_1 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}
.strategy_box_2 {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
}
.strategy_box {
  position: absolute;
  left: 33%;
  top: 10%;
}
.strategy {
  position: relative;
}
.enterprise_imgS_click img {
  width: 22px;
  height: 22px;
}
.enterprise_imgS_click {
  position: absolute;
  right: 24%;
  top: 69%;
  display: flex;
  cursor: pointer;
  
}
.enterprise_imgS_click div {
  font-size: 22px;
  margin: 0 20px 0 20px;
  font-weight: 400;
}
.enterprise_imgS {
  display: flex;
}
.enterprise_box_tit03 {
  text-align: center;
}
.enterprise_ig {
  width: 50px;
  height: 50px;
}
/* 底部 */
.About_m {
  display: flex;
  position: absolute;
  left: 35%;
  top: 76%;
}
.abot_me {
  color: #fff;
  padding: 0 40px 0 40px;
  border-right: 1px solid #fff;
}
.abot_me:nth-child(4) {
  border-right: none;
}
.hellow_img5_content {
  position: relative;
  height: 500px;
  width: 100%;

  background: rgba(34, 34, 34, 1);
}
.hellow_img5_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: #000;
  line-height: 80px;
  color: silver;
  font-size: 18px;
}
.hellow_img5_01 {
  position: absolute;
  color: #fff;
  left: 20%;
  top: 10%;
}
.hellow_img5_mido {
  position: absolute;
  color: #fff;
  left: 50%;
  top: 10%;
}
.hellow_img5_02 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 20px;
  text-align: left;
}
.hellow_img5_03 {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.hellow_img5_GF {
  position: absolute;
  right: 20%;
  top: 14%;
  color: #fff;
}
/* ===================================================================== */
.people_content_background {
  width: 200px;
  height: 600px;
  background: #ff7733;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.people_content_Y {
  display: flex;
  align-items: center;
  position: absolute;
  right: 30%;
  top: 65%;
}
.people_content_R {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0%;
  top: 7%;
}
.people_content_X {
  display: flex;
  align-items: center;
  position: absolute;
  left: 2%;
  top: 7%;
}
.people_content_xian {
  width: 30px;
  height: 1px;
  background: rgba(51, 51, 51, 1);
}
.people_img_4 {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.people_img_2 {
  width: 60%;
  position: absolute;
  left: 19.7%;
  top: 5%;
}
.people_img_2 .people_img {
  margin-left: 10px;
}
.people_content_modo {
  width: 72%;
  height: 80%;
  /* background: blue; */
  position: absolute;
  left: 13%;
  top: 10%;
}
.people_img_1 {
  display: grid;
  position: absolute;
  left: 0;
  top: 10%;
}
.people_img_3 {
  display: grid;
  position: absolute;
  right: 0;
  top: 10%;
}
.people_img {
  width: 332px;
  height: 226px;
  margin-top: 10px;
}
.people_img_last {
  width: 650px;
  height: 240px;
  margin-top: 10px;
}
.people_content {
  margin-top: 10px;
}
.people {
  width: 100%;
  height: 1000px;
  position: relative;
  /* background: red; */
}
.carousel {
  width: 100%;
  overflow: hidden;
}

.track {
  display: flex;
  animation-timing-function: linear;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slide {
  flex: 0 0 auto;
  width: 20%; /* 假设展示5张图片，每张占20%宽度 */
}
.slideS {
  flex: 0 0 auto;
  width: 25%; /* 假设展示5张图片，每张占20%宽度 */
}
.qyhonor_tit_box_imgSS {
  position: absolute;
  left: 10%;
  top: 72%;
  width: 80%;
}
.qyhonor_tit_box_img_S {
  width: 310;
  height: 214px;
}
.qyhonor_tit_box_imgS {
  position: absolute;
  left: 10%;
  top: 35%;
  width: 80%;
}
.qyhonor_tit_box_img {
  width: 250px;
  height: 334px;
}
.qyhonor_tit_box {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 41px;
  color: rgba(51, 51, 51, 1);
  margin-top: 20px;
}
.qyhonor_tit {
  position: absolute;
  left: 36%;
  top: 10%;
}
.qyhonor {
  position: relative;
}
.Team_content_box {
  flex: 1;
  text-align: center;
  margin-top: 30px;
}
.Team_content_box_1 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}
.Team_content_box_2 {
  font-size: 20px;
  font-weight: 400;
}
.Team_content {
  width: 70%;
  /* background: red; */
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.Team_box {
  position: absolute;
  left: 44%;
  top: 30%;
}
.Team {
  position: relative;
}
.Team_img {
  width: 100%;
}

.enterprise_imgS {
  position: absolute;
  right: 10%;
  top: 10%;
  z-index: 1;
}
.enterprise_imgS img {
  width: 389px;
  height: 486px;
  margin-left: 10px;
}

.enterprise_box_color_u {
  border-bottom: 1px solid rgba(244, 110, 27, 1);
}
.enterprise_box_tit02_content {
  display: flex;
  justify-content: space-between;
  width: 38%;
}
.enterprise_box_tit02_box {
  margin-top: 60px;
}
.enterprise_box_color_b {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
  line-height: 30px;
}
.enterprise_box_color {
  font-size: 40px;
  font-weight: 500;
  color: rgba(255, 141, 26, 1);
}
.enterprise_box_tit02_tit {
  font-weight: 900;
  font-size: 30px;
}
.enterprise_boxit {
  display: flex;
  justify-content: space-between;
  width: 20%;
  margin-top: 100px;
}
.enterprise_boxit div {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}
.enterprise_box_tit01 {
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
}
.enterprise_box_tit {
  font-size: 40px;
  font-weight: 700;
}
img {
  vertical-align: top;
}

.enterprise {
  position: relative;
}
.enterprise_box {
  width: 80%;
  height: 800px;
  /* background: red; */
  position: absolute;
  left: 15%;
  top: 10%;
  text-align: left;
}
.content_yu {
  font-size: 100px;
  font-weight: 900;

  color: rgba(255, 255, 255, 1);
}
.content_us {
  font-size: 60px;
  font-weight: 500;

  color: rgba(255, 255, 255, 1);
  text-align: left;
}
.cente_1 {
  position: relative;
}
.content_1 {
  position: absolute;
  left: 20%;
  top: 60%;
}
.content_1{
  position: absolute;
  /* left: 20%;
  top: 60%; */
  opacity: 0;
  animation: slideIn 2s forwards;
}
@keyframes slideIn {
    from {
        opacity: 0;
        bottom: 0%;
        left: 22%;

    }
    to {
        opacity: 1;
        left: 20%;
        top: 60%;
        
    }
}
</style>