<template>
  <div class="hello">
    <div class="hello_1">
      <div class="image-container">
  <img class="hellow_img" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/f1b9a202405241522333678.png" alt />
  <div class="hello_text">
        <div class="hello_text_top">为您解决场景里的及时需求</div>
        <div class="hello_text_next">To solve your timely needs in the scene</div>
      </div>
      <div class="hello_shub">
        <div class="hello_shub_text">下滑</div>
        <img class="hello_shub_text_img"  src="../assets/photo_3.png" alt />
        <div style="  display: flex;flex-direction: column;margin: 10px 0 0 14px;">
          <div class="dian"></div>
          <div class="dian"></div>
          <div class="dian"></div>
        </div>
      </div>
</div>
    </div>

    <!-- 关于我们 -->
    <div class="about_us_wm">
      <img class="hellow_img2" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/d581b202405241523465699.png" alt />
    <div class="about_us" >
      <div class="about_us_tit" ref="aboutUs">
        关于我们 ABOUT US
        <div class="xian"></div>
      </div>
      <div class="about_us_1" >重庆橘色盒子科技有限公司</div>
      <div
        class="about_us_2" 
      >隶属于“中科国瑞科技集团”旗下全资控股子公司，2019年8月成立于美丽的山城重庆，致力于用信息化、数字化为第一生产力的科技型新零售企业，历经三年疫情的磨砺与成长，核心业务“橘色盒子酒店客房情趣用品无人售货机”已成为赛道独角兽企业。</div>
      <div class="about_us_3" >目前已服务</div>
      <div class="about_us_3_box" >
        <div class="about_us_4 about_us_4_tit">
          <!-- <div class="about_us_4_tit">超1万家</div> -->
          超 <CountTo class="about_us_4_tit"
  :startVal='startVal'
  :endVal='endVal'
  :duration='duration'
/>家
          <div class="about_us_4_bom">酒店</div>
        </div>
        <div class="about_us_4 about_us_4_tit">
          超 <CountTo class="about_us_4_tit"
  :startVal='startVal1'
  :endVal='endVal1'
  :duration='duration'
/>间
          <div class="about_us_4_bom">客房</div>
        </div>
        <div class="about_us_4_1 about_us_4_tit">
          超 <CountTo class="about_us_4_tit"
  :startVal='startVal2'
  :endVal='endVal2'
  :duration='duration'
/>万
          <div class="about_us_4_bom">情趣用户</div>
        </div>
      </div>
      <div class="about_us_5" >并以“橘色盒子酒店客房情趣用品无人售货机”为精准流量入口逐渐形成私域生态</div>
      <div class="checkmore" @click="toLink2('/Aboutme')">
        查看更多
        <!-- <img class="checkmoreimg" src="../assets/photo_4.png" alt /> -->
      </div>
    </div>
    </div>
   
    <!--项目介绍  -->
    <div class="hellow_img3">
      <img class="hellow_img3" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/1ba07202405241524257209.png" alt />
      <div class="hellow_img3_text">
        <div style="display:flex; align-items: center;">
          <div class="hellow_img3_sty" style="border-bottom:3px solid #fff;padding-bottom:10px">项目介绍</div>
          <span
            class="hellow_img3_sty"
            style="font-weight: 400;margin:0 0 0 15px"
          >PROJECT INTRODUCTION</span>
        </div>
        <div class="hellow_img3_wm">橘色盒子酒店客房情趣用品无人售货机</div>
        <div class="about_us_2_2">为酒店的入住客人解决场景内使用安全套及情趣用品的及时需求</div>
        <div class="hellow_img3_wm_checkmore" style="margin-top:40px" @click="toLink3('/Heart')">了解详情</div>
        <div>
          <el-carousel
            class="carousel"
            :interval="2000"
            type="card"
            height="400px"
            style="width:200%;"
          >
          <el-carousel-item v-for="item in imageUrls" :key="item">
  <div class="carousel-item-wrapper">
    <img :src="item" class="carousel-image" />
  </div>
</el-carousel-item>

          </el-carousel>
              <!-- <div class="container">
        <div class="carousel">
            <div class="item a"></div>
            <div class="item b"></div>
            <div class="item c"></div>
            <div class="item d"></div>
            <div class="item e"></div>
            <div class="item f"></div>
        </div>
    </div> -->
        </div>
      </div>
    </div>

    <!-- 企业荣誉 -->
    <div style="position:relative">
      <img class="hellow_img4" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/0c075202405241524546325.png" alt />
      <div class="about_us_tit_t">
        企业荣誉 CORPORATE HONORES
        <div class="xian"></div>
      </div>
      <div class="about_us_tit_t_wz">
        <div>证件齐全·放心企业·国家认证用权威说话值得信赖</div>
        <div>用权威说话值得信赖</div>
        <div>正规化经营模式，标准核心业务资质，严格把控产品质量关</div>
      </div>
      <div >

        <div class="qyhonor_tit_box_imgS">
    <div class="carouselS">
    <section>
        <div class="swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide swiper-slide--one" v-for="(img, index) in imageUrlsS" :key="index">
    <img class="qyhonor_tit_box_img" :src="img" alt="">
                </div>      
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination"></div>
        </div>
    </section>
  </div>
  </div>
      </div>
    </div>
    <!-- 社会责任 -->
    <div style="position:relative">
      <img class="hellow_img5" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/9332b202405241525208110.png" alt />
      <div style="position:absolute;top:15%;left:35%">
        <div class="hellow_img5_tit">社会责任及价值</div>
        <div class="hellow_img5_next">SOCIAL RESPONSIBILITY AND VALUES</div>
      </div>
      <div class="hellow_img5_left" @mouseover="isHover = true" @mouseleave="isHover = false">
        <img style="width:100%" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/f2d45202405241525373384.png" alt />
        <img class="hellow_img5_left_img" src="../assets/photo_10.png" alt />
        <div class="hellow_img5_wz">PREVENTING AIDS</div>
        <div class="hellow_img5_wz_1">为防艾工作尽一份力</div>
        <div class="hellow_img5_wz_2" :class="{ 'show': isHover }">数据显示每年艾滋病增长为30%，而酒店为艾滋病传染第一发生场景</div>
      </div>
      <div class="hellow_img5_right" @mouseover="isHover_1 = true" @mouseleave="isHover_1 = false">
        <img style="width:100%" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/e588d202405241526126978.png" alt />
        <img class="hellow_img5_left_img" src="../assets/photo_11.png" alt />
        <div class="hellow_img5_wz">MARRIAGE</div>
        <div class="hellow_img5_wz_1">为降低离婚率尽一份力</div>
        <div
          class="hellow_img5_wz_2"
          :class="{ 'show_1': isHover_1 }"
        >数据显示目前中国离婚率为43.5%，而两性生活不协调导致离婚的占比超过50%</div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="hellow_img5_content">
      <div class="hellow_img5_01">
        <div class="hellow_img5_03">
          <img src="../assets/photo_12.png" alt />
          <div style="margin-left:10px">服务热线</div>
        </div>
        <div class="hellow_img5_02">400-023-0001</div>
        <div class="hellow_img5_03">
          <img src="../assets/photo_13.png" alt />
          <div style="margin-left:10px">公司地址</div>
        </div>
        <div style="width:300px;text-align: left;margin-top:20px">重庆市江北区北滨一路186号珠江国际A1写字楼橘色盒子总部13层</div>
      </div>
      <div class="hellow_img5_mido">
        <div class="hellow_img5_03">
          <img src="../assets/photo_15.png" alt />
          <div style="margin-left:10px">企业邮箱</div>
        </div>
        <div style="width:300px;text-align: left;margin-top:20px">bd@jusehezi.com</div>
        <div style="margin-top:60px">
          <div class="hellow_img5_03">
          <img src="../assets/photo_16.png" alt />
          <div style="margin-left:10px">网址</div>
        </div>
        <div style="width:300px;text-align: left;margin-top:20px">www.jusehezi.com</div>
        </div>  
      </div>
      <div class="hellow_img5_GF">
        <img src="../assets/photo_17.png" alt="">
        <div style="margin-top:10px">【橘色盒子官方】</div>
      </div>
      <div class="About_m" >
        <div class="abot_me" @click="toLink2('/Aboutme')">关于我们</div>
        <div class="abot_me" @click="toLink3('/Heart')">核心项目</div>
        <div class="abot_me" @click="toLink4('/Industry')">商业模式</div>
        <div class="abot_me" @click="toLink5('/future')">企业未来</div>
      </div>
      <div class="hellow_img5_footer">© 2019-2024 橘色盒子(重庆橘色盒子科技有限公司)版权所有 - <a class="a" href="https://beian.miit.gov.cn/"
                            target="_blank"
                            >渝ICP备19012992号-1</a></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import CountTo from 'vue-count-to';

export default {
  components: {
  CountTo
},
  name: "HelloWorld",
  props: {
    msg: String
  },
  data() {
    return {
      images: [
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/76954202405221656401478.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/8f390202405221657091399.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/c92e2202405221657342053.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/507c0202405221657596271.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/c55e1202405221658272696.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/a78f1202405221658586254.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/7d518202405221659463764.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/ec274202405221700273497.png",      
      ],
      imageUrls: [
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/185e3202405211447188951.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/4de1a202405211448135390.jpg",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/1a08320240521144857167.jpg",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/d1159202405211449375861.jpg",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/55e5b202405211449532463.jpg",
      ],
      imageUrlsS:[
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/e789b202406031633328721.jpg",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/0118c202406031634592622.jpg",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/047a1202406031635462637.jpg",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/19703202406031636291484.jpg",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/5f6b8202406031637093893.jpg",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/b5075202406031638152891.jpg",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/0b1b5202406031638527680.jpg",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/ec274202405221700273497.png",      
      ],
      isHover: false,
      isHover_1: false,
      startVal: 0,
      endVal: 10000,
      startVal1: 0,
      endVal1: 300000,
      startVal2: 0,
      endVal2: 1000,
      duration: 3000,
      timer: null

   
    };
  },
  computed: {
    imagesWithDuplicate() {
      return [...this.images, ...this.images];
    },
 
  },
  mounted() {
    var swiper = new Swiper(".swiper", {
  effect: "coverflow",
  grabCursor: true,
  centeredSlides: true,
  slidesPerView: "auto",
  coverflowEffect: {
    rotate: 0,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true
  },
  spaceBetween: 60,
  loop: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  autoplay: {
    delay: 1000 // 设置每秒自动轮播
  }
});

    //////////////////////////////////////////////////////////////////////////
    let lastScrollTop = 0;
window.addEventListener("scroll", function() {
  let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
  if (currentScroll > lastScrollTop) {
    console.log(currentScroll > lastScrollTop,"currentScroll > lastScrollTop")
    // 鼠标向下滚动
    // 在这里触发你的代码逻辑
    const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5 // 元素可见性的阈值，这里设置为50%
  };

  const callback = (entries, observer) => {
    entries.forEach(entry => {
     
      if (entry.isIntersecting) {
        // 元素进入可视区域
        entry.target.style.transition = "opacity 0.5s, transform 0.5s"; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 1; // 设置元素的透明度为1，实现淡入效果
        entry.target.style.transform = "translateY(0)"; // 从下往上出现
      } else {
        // 元素离开可视区域
        entry.target.style.transition = "opacity 0.5s, transform 0.5s"; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 0; // 设置元素的透明度为0，实现淡出效果
        entry.target.style.transform = "translateY(20px)"; // 元素向上移动隐藏
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  const targets = document.querySelectorAll('.about_us_3_box,.about_us_tit, .about_us_1,.about_us_2,.about_us_3,.about_us_4,.about_us_5,.checkmore,.hellow_img3_sty,.hellow_img3_wm,.about_us_2_2,.hellow_img3_wm_checkmore,.about_us_tit_t,.about_us_tit_t_wz,.qyhonor_tit_box_imgS,.hellow_img5_tit,.hellow_img5_next,.hellow_img5_left,.hellow_img5_right'); // 选择你想观察的标签的类名或选择器
  targets.forEach(target => observer.observe(target));

  } else {
    // 鼠标向上滚动
    // 在这里不触发你的代码逻辑
    console.log(123)
    const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5 // 元素可见性的阈值，这里设置为50%
  };

  const callback = (entries, observer) => {
    entries.forEach(entry => {
     
      if (entry.isIntersecting) {
        // 元素进入可视区域
        entry.target.style.transition = " "; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 1; // 设置元素的透明度为1，实现淡入效果
        entry.target.style.transform = ""; // 从下往上出现
      } else {
        // 元素离开可视区域
        entry.target.style.transition = ","; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 1; // 设置元素的透明度为0，实现淡出效果
        entry.target.style.transform = ""; // 元素向上移动隐藏
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  const targets = document.querySelectorAll('.about_us_3_box,.about_us_tit, .about_us_1,.about_us_2,.about_us_3,.about_us_4,.about_us_5,.checkmore,.hellow_img3_sty,.hellow_img3_wm,.about_us_2_2,.hellow_img3_wm_checkmore,.about_us_tit_t,.about_us_tit_t_wz,.qyhonor_tit_box_imgS,.hellow_img5_tit,.hellow_img5_next,.hellow_img5_left,.hellow_img5_right'); // 选择你想观察的标签的类名或选择器
  targets.forEach(target => observer.observe(target));

  }
  lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // 当滚动到顶部时重置滚动位置
}, false);
// 
// this.timer = setInterval(() => {
//       this.endVal = this.endVal * 2
//     }, 4000)

 
},
destroyed() {
    clearInterval(this.timer)
  },

  methods: {
    getImageUrl(index) {
      return this.imageUrls[index - 1];
    },

    toLink2() {
            this.$router.push({ path: '/Aboutme' })
        },
        toLink3() {
            this.$router.push({ path: '/Heart' })
        },
        toLink4() {
            this.$router.push({ path: '/Industry' })
        },
        toLink5() {
            this.$router.push({ path: '/future' })
        },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../style.css';
.a{
  color: silver;
}
.a:hover {
    color: orange;
}
.vue-count-to {
  width: 100%;
  height: 100%;
}
.count-to {
  width: 300px;
  height: 300px;
  margin: 100px 0 0 100px;
  border: 1px solid red;
}
.count-to span {
  font-size: 30px;
  font-weight: 700;
  font-family: 'YJSZ';
}
.count-to > div:nth-of-type(1) > span {
  color: red;
}
.count-to > div:nth-of-type(2) > span {
  color: blue;
}
.count-to > div:nth-of-type(3) > span {
  color: pink;
}
.hellow_img5_03 img{
  width: 28px;
  height: 28px;
}
.hellow_img5_GF img{
width: 157px;
height: 157px;
}
.carousel-item-wrapper {
  position: relative;
  overflow: hidden;
}

.carousel-image {
  transition: transform 0.3s;
}

.carousel-image:hover {
  transform: scale(1.2);
}

.about_us_wm{
  position: relative;
}
.carousel {
  width: 100%;
  overflow: hidden;
}

.track {
  display: flex;
  animation-timing-function: linear;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slide {
  flex: 0 0 auto;
  width: 20%; /* 假设展示5张图片，每张占20%宽度 */
}
.slideS {
  flex: 0 0 auto;
  width: 25%; /* 假设展示5张图片，每张占20%宽度 */
}
.qyhonor_tit_box_imgSS{
  position: absolute;
  left: 10%;
  top: 72%;
  width: 80%;
}
.qyhonor_tit_box_img_S{
  width: 310;
  height: 214px;
}
.qyhonor_tit_box_imgS{
  position: absolute;
  left: 10%;
  top: 40%;
  width: 80%;
}
.qyhonor_tit_box_img{
  width: 300px;
    height: 450px;
   margin-left: 10px;
}
.qyhonor_tit_box{
  font-size: 20px;
font-weight: 400;
letter-spacing: 0px;
line-height: 41px;
color: rgba(51, 51, 51, 1);
margin-top: 20px;
}
.qyhonor_tit{
  position: absolute;
  left: 36%;
  top: 10%;
}
.qyhonor{
  position: relative;
}
/* 底部 */
.About_m{
  display: flex;
  position: absolute;
  left: 35%;
  top: 76%;
}
.abot_me{
  color:#fff;
  padding: 0 40px 0 40px;
  border-right: 1px solid #fff;
}
.abot_me:nth-child(4) {
  border-right: none;
}
.hellow_img5_content {
  position: relative;
  height: 500px;
  width: 100%;
  margin-top: 20%;
  background: rgba(34, 34, 34, 1);
}
.hellow_img5_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: #000;
  line-height: 80px;
  color: silver;
  font-size: 18px;
}
.hellow_img5_01 {
  position: absolute;
  color: #fff;
  left: 20%;
  top: 10%;
  
}
.hellow_img5_mido{
  position: absolute;
  color: #fff;
  left: 50%;
  top: 10%;
}
.hellow_img5_02 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 20px;
  text-align: left;
}
.hellow_img5_03{
 display: flex;
 align-items: center;
 margin-top: 20px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.hellow_img5_wz_2 {
  position: absolute;
  left: 9%;
  top: 80%;
  width: 75%;
  font-size: 20px;
  font-weight: 400;
  opacity: 0;
  transition: opacity 0.5s;
  line-height: 40px;
    text-align: left;
}
.hellow_img5_GF{
  position: absolute;
  right: 20%;
  top: 14%;
  color: #fff;
}
.show {
  opacity: 1;
}
.show_1 {
  opacity: 1;
}
.hellow_img5_wz {
  position: absolute;
  left: 20%;
  top: 60%;
  color: rgba(244, 110, 27, 1);
  font-size: 30px;
}
.hellow_img5_wz_1 {
  position: absolute;
  left: 10%;
  top: 70%;
  font-size: 30px;
  font-weight: 900;
}
.hellow_img5_left_img {
  /* position: absolute;
  left: 0;
  top: 54%; */
  display: flex;
  margin: 20px 0 0 20px;
}
.hellow_img5_left {
  width: 30%;
  height: 100%;
  background: rgba(250, 250, 250, 1);
  position: absolute;
  left: 15%;
  top: 60%;
  border-radius: 20px;
  transition: all 0.3s ease; /* 添加过渡效果 */
}
.hellow_img5_left:hover {
  transform: scale(1.2); /* 鼠标悬停时放大1.1倍 */
  border: 1px solid rgba(244, 110, 27, 1); /* 显示外边框 */
}
.hellow_img5_right {
  width: 30%;
  height: 100%;
  background: rgba(250, 250, 250, 1);
  position: absolute;
  left: 55%;
  top: 60%;
  border-radius: 20px;
  transition: all 0.3s ease; /* 添加过渡效果 */
}
.hellow_img5_right:hover {
  transform: scale(1.2); /* 鼠标悬停时放大1.1倍 */
  border: 1px solid rgba(244, 110, 27, 1); /* 显示外边框 */
}
.hellow_img5_next {
  font-size: 30px;
  font-weight: 400;
  color: #fff;
  margin-top: 20px;
}
.hellow_img5_tit {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}
.about_us_tit_t_wz {
  position: absolute;
  top: 16%;
  left: 36%;
  line-height: 40px;
  font-size: 20px;
}
.about_us_tit_t {
  position: absolute;
  top: 5%;
  left: 34%;
  font-size: 40px;
  border-bottom: 2px solid;

  padding-bottom: 20px;
}
.carousel_1 {
  position: absolute;
  top: 6%;
  left: 30%;
}
.carouselS{
  overflow: hidden;
}
.carousel {
  /* width: ; */
  position: absolute;
  top: 45%;
  left: -50%;
}

.el-carousel__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hellow_img3_sty {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
}
.hellow_img3_text {
  position: absolute;
  top: 4.4%;
  left: 30%;
  /* width: 680px; */
  height: 86.6%;
  opacity: 1;
  background: rgba(244, 110, 27, 1);
  padding: 40px;
  text-align: left;
}
img {
  vertical-align: top;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}

html,
body {
  margin: 0;
  padding: 0;
}

/* .hello_1{
  background-image: url("../assets/photo_1.png");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  height: 100vh; 
  width: 100%; 

} */

.hellow_img5 {
  width: 100%;
}
.hellow_img4 {
  width: 100%;
}
.hellow_img3 {
  width: 100%;
  position: relative;
}
.hellow_img3_wm_checkmore {
  color: #fff;
  width: 178px;
  line-height: 38px;
  opacity: 1;
  border-radius: 80px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 1);
  position: relative;
  margin-top: 60px;
  cursor: pointer;
  font-size: 20px;
}
.hellow_img3_wm_checkmore:hover {
  background-color: #f0f0f0; /* 设置背景颜色变化 */
  color: rgba(244, 110, 27, 1); /* 设置字体颜色变化 */
}
.checkmore {
  color: #fff;
  width: 178px;
  line-height: 38px;
  opacity: 1;
  border-radius: 80px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 1);
  position: relative;
  margin-top: 60px;
  cursor: pointer;
  font-size: 20px;
}
.checkmore:hover {
  background-color: #fff; /* 设置背景颜色变化 */
  color: rgba(244, 110, 27, 1); /* 设置字体颜色变化 */
}

.checkmoreimg {
  position: absolute;
  right: 16px;
  top: 10px;
  width: 18px;
  height: 18px;
}
.about_us_5 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 41px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  margin-top: 80px;
}
.about_us_3_box {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.about_us_4_bom {
  color: #fff;
  margin-top: 25px;
  font-size: 20px;
}
.about_us_4_tit {
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 0px;
  line-height: 41px;
  color: rgba(255, 255, 255, 1);
}
.about_us_4 {
  width: 280px;
  height: 100px;

  margin-top: 30px;
  border-right: 2px solid rgba(255, 255, 255, 0.5);
}
.about_us_4_1 {
  width: 230px;
  height: 100px;

  margin-top: 30px;
}
.about_us_3 {
  font-size: 20px;
  color: #fff;
  width: 65%;
  line-height: 40px;
  font-weight: 500;
}
.about_us_2 {
  font-size: 20px;
  color: #fff;
  width: 65%;
  line-height: 40px;
  font-weight: 500;
}
.about_us_2_2 {
  font-size: 20px;
  color: #fff;

  line-height: 40px;
  font-weight: 500;
}
.about_us_1 {
  font-size: 30px;
  color: #fff;
  margin-top: 40px;
  font-weight: 900;
}
.hellow_img3_wm{
  font-size: 30px;
  color: #fff;
  margin-top: 40px;
  font-weight: 900;
}
.xian {
  width: 160px;
  height: 4px;
  background: rgba(244, 110, 27, 1);
  position: absolute;
  bottom: -2px;
}
.about_us_tit {
  font-size: 40px;
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 20px 0 20px 0;
  position: relative;
}
.image-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.hellow_img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  animation: zoom-in-out 10s ;
}

@keyframes zoom-in-out {
  0% {
    transform: scale(1.1);
  }
  
  100% {
    transform: scale(1);
  }
}

.hellow_img2 {
  height: 100%;
  width: 100%;
}
.hello_text {
  position: absolute;
  text-align: center;
  /* left: 22%; */
  /* top: 40%; */
  opacity: 0;
  animation: slideIn 2s forwards;
}
@keyframes slideIn {
    from {
        opacity: 0;
        bottom: 20%;
        left: 22%;

    }
    to {
        opacity: 1;
         left: 22%;
        bottom: 40%;
        
    }
}
.hello_text_top {
  font-size: 70px;
  font-weight: 400;
  letter-spacing: 21px;
  color: rgba(255, 255, 255, 1);
}
.hello_text_next {
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 52.76px;
  color: rgba(255, 255, 255, 1);
}
.hello_shub {
  position: absolute;
  left: 50%;
  top: 86%;
}
.hello_shub_text {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
.hello_shub_text_img{
  width: 32px;
  height: 32px;
  margin-top: 10px;

}
.dian {
  width: 8px;
  height: 8px;
  background-color: grey;
  border-radius: 50%;
  display: inline-block;
  margin-top: 3px;
}

.dian:nth-child(1) {
  animation: colorChange 3s infinite;
}

.dian:nth-child(2) {
  animation: colorChange 3s infinite 1s;
}

.dian:nth-child(3) {
  animation: colorChange 3s infinite 2s;
}

@keyframes colorChange {
  0%,
  100% {
    background-color: grey;
  }
  50% {
    background-color: white;
  }
}
.about_us {
  /* width: 100%;
  height: 60%; */
  position: absolute;
  top: 0%;
  left: 10%;
  text-align: left;
  padding: 50px 0 0 50px;
  
}
</style>
