<template>
  <div>
    <!-- 顶部 -->
    <Head/>
    <div class="cente_1" >
      <img style="width:100%"  src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/8af97202405241521203512.png" alt="">
      <div class="content_1">
        <div class="content_us">关于我们</div>
        <div class="content_yu">ABOUT US</div>
      </div>
    </div>
    <!-- 企业介绍 -->
    <div class="enterprise">
      <img  style="width:100%" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/f816d202405241521427019.jpg" alt="">
      <div class="enterprise_box">
        <div class="enterprise_box_tit">企业介绍</div>
        <div class="enterprise_box_tit01">Company Introduction</div>
        <div class="enterprise_box_tit02">
          <span class="enterprise_box_tit02_tit">重庆橘色盒子科技有限公司</span><br>
隶属于“中科国瑞科技集团”旗下全资控股子公司，2019年8月成立于美丽的山城重庆，致力于用信息化、数字化为第一生产力的科技型新零售企业，历经三年疫情的磨砺与成长，核心业务“橘色盒子酒店客房情趣用品无人售货机”已成为赛道独角兽企业。</div>
          <div class="enterprise_box_tit02">目前已服务</div>
   <div class="enterprise_box_tit02_content">
    <div class="enterprise_box_tit02_box">
            <div class="enterprise_box_color">
              超1万家
            </div>
            <div class="enterprise_box_color_b">酒店</div>
           </div>
           <div class="enterprise_box_tit02_box">
            <div class="enterprise_box_color">
              超30万间
            </div>
            <div class="enterprise_box_color_b">客房</div>
           </div>
           <div class="enterprise_box_tit02_box">
            <div class="enterprise_box_color">
              超1000万家
            </div>
            <div class="enterprise_box_color_b">情趣用户</div>
           </div>
      </div>
      <div class="enterprise_box_tit02_last" style="margin-top:100px">并以“橘色盒子酒店客房情趣用品无人售货机”为精准流量入口逐渐形成私域生态。</div>
    
   </div>
      <div class="enterprise_img">
        <img class="enterprise_img1" style="z-index:99" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/53d41202405241527257496.png" alt="">
        <div class="enterprise_img_k" ></div>
      </div>
      <img class="enterprise_img_1" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/f6eb5202405241535176412.png" alt="">
    </div>
    <!-- 核心团队 -->
    <div class="Team">
      <img class="Team_img"  src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/75e9e202405241535333250.png" alt="">
      <div class="Team_box">
        <div class="enterprise_box_tit">核心团队</div>
        <div class="enterprise_box_tit01">CORE TEAM</div>
      </div>
    </div>
    <div style="padding-bottom: 40px;">
      <div class="Team_content">
  <div class="Team_content_box">
    <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/ddb7c202405271540244334.png" alt="">
    <div class="Team_content_box_1">曹杨</div>
    <div class="Team_content_box_2">董事长</div>
  </div>
  <div class="Team_content_box">
    <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/965fc202405271541545692.png" alt="">
    <div class="Team_content_box_1">刘兴宇</div>
    <div class="Team_content_box_2">技术总监</div>
  </div>
  <div class="Team_content_box">
    <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/db2a2202405271542507224.png" alt="">
    <div class="Team_content_box_1">虞智君</div>
    <div class="Team_content_box_2">供应链总监</div>
  </div>
</div>
<div class="Team_content">
  <div class="Team_content_box">
    <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/82280202405271548223398.jpg" alt="">
    <div class="Team_content_box_1">徐利黠</div>
    <div class="Team_content_box_2">董秘兼杭州橘色盒子总经理</div>
  </div>
  <div class="Team_content_box">
    <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/92149202405271545412529.png" alt="">
    <div class="Team_content_box_1">黄潘</div>
    <div class="Team_content_box_2">海南橘色橘色总经理</div>
  </div>
  <div class="Team_content_box">
    <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/86321202405271546398911.png" alt="">
    <div class="Team_content_box_1">罗明镜</div>
    <div class="Team_content_box_2">贵州橘色盒子总经理</div>
  </div>
  <div class="Team_content_box">
    <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/1a700202405271547181065.png" alt="">
    <div class="Team_content_box_1">张洪谦</div>
    <div class="Team_content_box_2">橘色力量总经理</div>
  </div>
</div>
    </div>

<!-- 企业荣誉 -->
<div class="qyhonor">
  <img style="width:100%" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/af9ed202405241534559607.png" alt="">
  <div class="qyhonor_tit">
    <div class="enterprise_box_tit">企业荣誉</div>
  <div class="enterprise_box_tit01">CORPORATE HONORES</div>
  <div class="qyhonor_tit_box">
   <div>证件齐全·放心企业·国家认证</div>
   <div>用权威说话值得信赖</div>
   <div>正规化经营模式，标准核心业务资质，严格把控产品质量关</div>
  </div>
  </div>
  <div class="qyhonor_tit_box_imgS">
    <div class="carousel">
    <div class="track" :style="{ 'animation': 'scroll 10s linear infinite' }">
      <div class="slide" v-for="(img, index) in imagesWithDuplicate" :key="index">
        <img class="qyhonor_tit_box_img" :src="img" alt="">
      </div>
    </div>
  </div>
  </div>
  <div class="qyhonor_tit_box_imgSS">
    <div class="carousel">
    <div class="track" :style="{ 'animation': 'scroll 10s linear infinite' }">
      <div class="slideS" v-for="(img, index) in imagesWithDuplicate_s" :key="index">
        <img class="qyhonor_tit_box_img_S" :src="img" alt="">
      </div>
    </div>
  </div>
  </div>

</div>
<!-- 员工风采 -->
<div class="people">
  <div class="people_content">
    <div class="enterprise_box_tit">员工风采</div>
  <div class="enterprise_box_tit01">EMPLOYEE STYLE</div>
  <div class="people_content_modo">
    <div class="people_img_1">
      <img class="people_img" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/e75ec202405241537251114.jpg" alt="">
      <img class="people_img" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/d1318202405241537362472.jpg" alt="">
     </div>
     <div class="people_img_2">
      <img class="people_img" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/b3833202405241537514153.jpg" alt="">
      <img class="people_img" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/a9c14202405241538094709.jpg" alt="">
      <img class="people_img" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/7eff2202405241538257764.jpg" alt="">
      <img class="people_img" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/18362202405241538383819.jpg" alt="">
     </div>
     <div class="people_img_3" >
      <img class="people_img" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/f4d02202405241538599207.jpg" alt="">
      <img class="people_img" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/1dc27202405241539091653.jpg" alt="">
     </div>
     <div class="people_img_4" >
      <img class="people_img_last" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/b4a3e202405241539228956.jpg" alt="">
      <img class="people_img_last" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/6c387202405241539342094.jpg" alt="">
     </div>
     <div class="people_content_X">
      <div class="people_content_xian"></div>
      <div class="people_content_X_text">团结 SOLIDARITY</div>
      <div class="people_content_xian"></div>
     </div>
     <div class="people_content_R">
      <div class="people_content_xian"></div>
      <div class="people_content_R_text">凝聚力 COHESIVE FORCE</div>
      <div class="people_content_xian"></div>
     </div>
     <div class="people_content_Y">
      <div class="people_content_xian"></div>
      <div class="people_content_R_text">因为有你所以精彩 Because of you, it's wonderful</div>
      <div class="people_content_xian"></div>
     </div>
     <div class="people_content_background"></div>
  </div>  
  </div>
</div>
<!-- 底部 -->
<div class="hellow_img5_content">
      <div class="hellow_img5_01">
        <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/a512320240528094722275.png" alt />
          <div style="margin-left:10px">服务热线</div>
        </div>
        <div class="hellow_img5_02">400-023-0001</div>
        <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/060ed202405280946206103.png" alt />
          <div style="margin-left:10px">公司地址</div>
        </div>
        <div style="width:300px;text-align: left;margin-top:20px">重庆市江北区北滨一路186号珠江国际A1写字楼橘色盒子总部13层</div>
      </div>
      <div class="hellow_img5_mido">
        <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/7c43d20240528094657182.png" alt />
          <div style="margin-left:10px">企业邮箱</div>
        </div>
        <div style="width:300px;text-align: left;margin-top:20px">bd@jusehezi.com</div>
        <div style="margin-top:60px">
          <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/30426202405280940261584.png" alt />
          <div style="margin-left:10px">网址</div>
        </div>
        <div style="width:300px;text-align: left;margin-top:20px">www.jusehezi.com</div>
        </div>  
      </div>
      <div class="hellow_img5_GF">
        <img  src="@/assets/photo_17.png" alt="">
        <div style="margin-top:10px">【橘色盒子官方】</div>
      </div>
      <div class="About_m" >
        <div class="abot_me" @click="toLink2('/Aboutme')">关于我们</div>
        <div class="abot_me" @click="toLink3('/Heart')">核心项目</div>
        <div class="abot_me" @click="toLink4('/Industry')">商业模式</div>
        <div class="abot_me" @click="toLink5('/future')">企业未来</div>
      </div>
      <div class="hellow_img5_footer">© 2019-2024 橘色盒子(重庆橘色盒子科技有限公司)版权所有 - <a class="a" href="https://beian.miit.gov.cn/"
                            target="_blank"
                            >渝ICP备19012992号-1</a></div>
    </div>
  </div>
</template>

<script>
import Head from './components/Head.vue'
export default {
    components: {
    Head
  },
  data() {
    return {
      images: [
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/76954202405221656401478.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/8f390202405221657091399.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/c92e2202405221657342053.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/507c0202405221657596271.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/c55e1202405221658272696.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/a78f1202405221658586254.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/7d518202405221659463764.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/ec274202405221700273497.png",      
      ],
      images_s: [
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/edbd1202405230929416315.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/c9f44202405230930061421.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/e58be202405230930225258.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/0c9a9202405230930472151.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/402ad2024052309311427.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/36967202405230932196473.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/f6024202405230932486622.png",
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/85b90202405230933129923.png",   
        "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/23a3f202405230934043634.png",          

      ]
    };
  },
  mounted() {
    let lastScrollTop = 0;
window.addEventListener("scroll", function() {
  let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
  if (currentScroll > lastScrollTop) {
    console.log(currentScroll > lastScrollTop,"currentScroll > lastScrollTop")
    // 鼠标向下滚动
    // 在这里触发你的代码逻辑
    const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5 // 元素可见性的阈值，这里设置为50%
  };

  const callback = (entries, observer) => {
    entries.forEach(entry => {
     
      if (entry.isIntersecting) {
        // 元素进入可视区域
        entry.target.style.transition = "opacity 0.5s, transform 0.5s"; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 1; // 设置元素的透明度为1，实现淡入效果
        entry.target.style.transform = "translateY(0)"; // 从下往上出现
      } else {
        // 元素离开可视区域
        entry.target.style.transition = "opacity 0.5s, transform 0.5s"; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 0; // 设置元素的透明度为0，实现淡出效果
        entry.target.style.transform = "translateY(20px)"; // 元素向上移动隐藏
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  const targets = document.querySelectorAll('.enterprise_box_tit,.enterprise_box_tit01,.enterprise_box_tit02,.enterprise_box_tit02_content,.enterprise_box_tit02_last,.Team_content,.qyhonor_tit_box,.qyhonor_tit_box_imgS,.qyhonor_tit_box_imgSS'); // 选择你想观察的标签的类名或选择器

  targets.forEach(target => observer.observe(target));

  } else {
    // 鼠标向上滚动
    // 在这里不触发你的代码逻辑
    console.log(123)
    const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5 // 元素可见性的阈值，这里设置为50%
  };

  const callback = (entries, observer) => {
    entries.forEach(entry => {
     
      if (entry.isIntersecting) {
        // 元素进入可视区域
        entry.target.style.transition = " "; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 1; // 设置元素的透明度为1，实现淡入效果
        entry.target.style.transform = ""; // 从下往上出现
      } else {
        // 元素离开可视区域
        entry.target.style.transition = ","; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 1; // 设置元素的透明度为0，实现淡出效果
        entry.target.style.transform = ""; // 元素向上移动隐藏
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  const targets = document.querySelectorAll('.enterprise_box_tit,.enterprise_box_tit01,.enterprise_box_tit02,.enterprise_box_tit02_content,.enterprise_box_tit02_last,.Team_content,.qyhonor_tit_box,.qyhonor_tit_box_imgS,.qyhonor_tit_box_imgSS'); // 选择你想观察的标签的类名或选择器

  targets.forEach(target => observer.observe(target));

  }
  lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // 当滚动到顶部时重置滚动位置
}, false);

 
},
  computed: {
    imagesWithDuplicate() {
      return [...this.images, ...this.images];
    },
    imagesWithDuplicate_s() {
      return [...this.images_s, ...this.images_s];
    },
  },
  methods:{
    toLink2() {
            this.$router.push({ path: '/Aboutme' })
        },
        toLink3() {
            this.$router.push({ path: '/Heart' })
        },
        toLink4() {
            this.$router.push({ path: '/Industry' })
        },
        toLink5() {
            this.$router.push({ path: '/future' })
        },
  }
}
</script>

<style scoped>
.a{
  color: silver;
}
.a:hover {
    color: orange;
}
.hellow_img5_03 img{
  width: 28px;
  height: 28px;
}
.hellow_img5_GF img{
width: 157px;
height: 157px;
}
.people_content_R_text{
  margin: 0 10px 0 10px;font-size:20px
}
.people_content_X_text{
  margin: 0 10px 0 10px;
  font-size:20px
}
.enterprise_img1{
  width: 660px;
  height: 564px;
}
/* 底部 */
.About_m{
  display: flex;
  position: absolute;
  left: 35%;
  top: 76%;
}
.abot_me{
  color:#fff;
  padding: 0 40px 0 40px;
  border-right: 1px solid #fff;
  cursor: pointer;

}
.abot_me:nth-child(4) {
  border-right: none;
}
.hellow_img5_content {
  position: relative;
  height: 500px;
  width: 100%;

  background: rgba(34, 34, 34, 1);
}
.hellow_img5_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: #000;
  line-height: 80px;
  color: silver;
  font-size: 18px;
}
.hellow_img5_01 {
  position: absolute;
  color: #fff;
  left: 20%;
  top: 10%;
  
}
.hellow_img5_mido{
  position: absolute;
  color: #fff;
  left: 50%;
  top: 10%;
}
.hellow_img5_02 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 20px;
  text-align: left;
}
.hellow_img5_03{
 display: flex;
 align-items: center;
 margin-top: 20px;
}
.hellow_img5_GF{
  position: absolute;
  right: 20%;
  top: 14%;
  color: #fff;
}
/* ===================================================================== */
.people_content_background{
  width: 200px;
  height: 600px;
  background: #FF7733;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.people_content_Y{
  display: flex;
  align-items: center;
  position: absolute;
  right: 30%;
  top: 65%;
}
.people_content_R{
  display: flex;
  align-items: center;
  position: absolute;
  right: 0%;
  top: 7%;
}
.people_content_X{
  display: flex;
  align-items: center;
  position: absolute;
  left: 2%;
  top: 7%;
}
.people_content_xian{
  width: 30px;
  height: 1px;
  background: rgba(51, 51, 51, 1);
}
.people_img_4{
  position: absolute;
  bottom: -9%;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.people_img_2{
  width: 60%;
  position: absolute;
  left: 19.7%;
  top: 5%;
}
.people_img_2 .people_img{
  margin-left: 10px;
}
.people_content_modo{
  width: 72%;
  height: 80%;
  /* background: blue; */
  position: absolute;
  left: 13%;
  top: 10%;
}
.people_img_1{
  display: grid;
  position: absolute;
  left:  0;
  top: 10%;
}
.people_img_3{
  display: grid;
  position: absolute;
  right: 0;
  top: 10%;
}
.people_img{
  width: 332px;
  height: 226px;
  margin-top: 10px;
}
.people_img_last{
  width: 650px;
  height: 300px;
  /* margin-top: 20px; */
}
.people_content{
  margin-top: 10px;
}
.people{
  width: 100%;
  height: 1000px;
  position: relative;
  /* background: red; */
}
.carousel {
  width: 100%;
  overflow: hidden;
}

.track {
  display: flex;
  animation-timing-function: linear;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slide {
  flex: 0 0 auto;
  width: 20%; /* 假设展示5张图片，每张占20%宽度 */
}
.slideS {
  flex: 0 0 auto;
  width: 25%; /* 假设展示5张图片，每张占20%宽度 */
}
.qyhonor_tit_box_imgSS{
  position: absolute;
  left: 10%;
  top: 72%;
  width: 80%;
}
.qyhonor_tit_box_img_S{
  width: 310;
  height: 214px;
}
.qyhonor_tit_box_imgS{
  position: absolute;
  left: 10%;
  top: 35%;
  width: 80%;
}
.qyhonor_tit_box_img{
  width: 250px;
  height: 334px;
}
.qyhonor_tit_box{
  font-size: 20px;
font-weight: 400;
letter-spacing: 0px;
line-height: 41px;
color: rgba(51, 51, 51, 1);
margin-top: 20px;
}
.qyhonor_tit{
  position: absolute;
  left: 36%;
  top: 10%;
}
.qyhonor{
  position: relative;
}
.Team_content_box {
  flex: 1;
  text-align: center;
  margin-top: 30px;
}
.Team_content_box img{
  width: 252px;
  height: 275px;
}
.Team_content_box_1{
  font-size: 20px;
font-weight: 700;
margin-top: 10px;
}
.Team_content_box_2{
  font-size: 20px;
font-weight: 400;

}
.Team_content{
  width: 70%;
  /* background: red; */
  display: flex;
  justify-content: center;
 margin: 0 auto;
}
.Team_box{
  position: absolute;
  left: 44%;
  top: 30%;
}
.Team{
  position: relative;
}
.Team_img{
  width: 100%;
}
.enterprise_img_1{
  position: absolute;
  right: 0;
  bottom: 20%;
  z-index: 999999999999;
  width: 628px;
  height: 100px;
}
.enterprise_img_k{
  width: 100px;
  height: 400px;
  background: rgba(255, 119, 51, 1);
  position: absolute;
  right: -7%;
  top: 40%;
  z-index: -99;
}
.enterprise_img{
  position: absolute;
  right: 10%;
  top: 10%;
  z-index: 1;
}
.enterprise_box_tit02_img{
  position: absolute;
  left: 0;
  right: 20%;

}
.enterprise_box_color_u{
  border-bottom: 1px solid rgba(244, 110, 27, 1);
}
.enterprise_box_tit02_content{
  display: flex;
  justify-content: space-between;
  width: 38%;
}
.enterprise_box_tit02_box{
  margin-top: 60px;
}
.enterprise_box_color_b{
  font-size: 20px;
font-weight: 500;
margin-top: 20px;

}
.enterprise_box_color{
  font-size: 40px;
font-weight: 500;
color: rgba(255, 141, 26, 1); 
  
}
.enterprise_box_tit02_tit{
  font-weight: 900;


}
.enterprise_box_tit02{
  width: 38%;
  font-size: 20px;
  line-height: 33px;
  margin-top: 50px;
}
.enterprise_box_tit02_last{
  width: 38%;
  font-size: 20px;
  line-height: 33px;
  margin-top: 50px;
}
.enterprise_box_tit01{
  font-size: 20px;
font-weight: 400;
margin-top: 10px;
}
.enterprise_box_tit{
  font-size: 40px;
font-weight: 700;
}
img {
  vertical-align: top;
}

.enterprise{
  position: relative;
}
.enterprise_box{
  width: 80%;
  height: 800px;
  /* background: red; */
  position: absolute;
  left: 15%;
  top: 10%;
  text-align: left;
}
.content_yu{
  font-size: 100px;
  font-weight: 900;

color: rgba(255, 255, 255, 1);
}
.content_us{
  font-size: 60px;
font-weight: 500;

color: rgba(255, 255, 255, 1);
text-align: left;
}
.cente_1{
  position: relative;
}
.content_1{
  position: absolute;
  /* left: 20%;
  top: 60%; */
  opacity: 0;
  animation: slideIn 2s forwards;
}
@keyframes slideIn {
    from {
        opacity: 0;
        bottom: 0%;
        left: 22%;

    }
    to {
        opacity: 1;
        left: 20%;
        top: 60%;
        
    }
}
</style>