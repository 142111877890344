import { render, staticRenderFns } from "./Aboutme.vue?vue&type=template&id=deccf262&scoped=true"
import script from "./Aboutme.vue?vue&type=script&lang=js"
export * from "./Aboutme.vue?vue&type=script&lang=js"
import style0 from "./Aboutme.vue?vue&type=style&index=0&id=deccf262&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "deccf262",
  null
  
)

export default component.exports