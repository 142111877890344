import Vue from 'vue'
import App from './App.vue'
// 在 main.js 中引入字体包
import '@/assets/fontFamily/font.css'
import router from '@/router/index.js'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui'
import './utils/rem'
import VueRouter from 'vue-router'
Vue.config.productionTip = false

Vue.use(ElementUI)
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
 
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
   return originalReplace.call(this, location).catch(err => err)
}
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
