<template>
  <div>
    <!-- 顶部 -->
    <Head />
    <div class="cente_1">
      <img style="width:100%" src="@/assets/future.png" alt />
      <div class="content_1">
        <div class="content_us">企业未来</div>
        <div class="content_yu">THE FUTURE</div>
      </div>
    </div>
    <!-- 为酒店 -->
    <div class="Hotels">
      <img style="width:100%;height:970px" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/b5f9c202405231625478445.png" alt />
      <div class="Hotelscontent">橘色盒子</div>
        <div class="xian_1"></div>
        <div class="xian1"></div>
        <div class="xian2"></div>
        <div class="xian3"></div>
        <div class="xian4"></div>
        <div class="xian5"></div>
      <div>
    <div class="Hotelscontent_box">
      <div
        class="Hotelscontent1"
        v-for="(content, index) in contentList"
        :key="index"
        @click="selectedContent = index"
        :class="{ active: selectedContent === index }"
      >
        {{ content.title }}
      </div>
    </div>
    <div class="Hotelscontent_box_bon">
      <div v-if="selectedContent !== -1" class="Hotelscontent_box_bonleft">
        <div class="Hotelscontent_box_bonleft1">
          {{ contentList[selectedContent].bonContent.title }}
        </div>
        <pre class="Hotelscontent_box_bonleft1">
          {{ contentList[selectedContent].bonContent.description }}
        </pre>
        <img
          v-for="(image, index) in contentList[selectedContent].bonContent.images"
          :key="index"
          :src="image"
          alt=""
        >
      </div>
      <div v-else class="Hotelscontent_box_bonleft">
        <!-- 默认显示的内容 -->
      </div>
      <div class="Hotelscontent_box_bonright">
        <img
          :src="contentList[selectedContent].bonContent.imagesS"
          alt=""
          v-if="selectedContent !== -1"
        >
      </div>
    </div>
  </div>
    </div>
    <!-- 底部 -->
    <div class="hellow_img5_content">
      <div class="hellow_img5_01">
        <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/a512320240528094722275.png" alt />
          <div style="margin-left:10px">服务热线</div>
        </div>
        <div class="hellow_img5_02">400-023-0001</div>
        <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/060ed202405280946206103.png" alt />
          <div style="margin-left:10px">公司地址</div>
        </div>
        <div style="width:300px;text-align: left;margin-top:20px">重庆市江北区北滨一路186号珠江国际A1写字楼橘色盒子总部13层</div>
      </div>
      <div class="hellow_img5_mido">
        <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/7c43d20240528094657182.png" alt />
          <div style="margin-left:10px">企业邮箱</div>
        </div>
        <div style="width:300px;text-align: left;margin-top:20px">bd@jusehezi.com</div>
        <div style="margin-top:60px">
          <div class="hellow_img5_03">
            <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/30426202405280940261584.png" alt />
            <div style="margin-left:10px">网址</div>
          </div>
          <div style="width:300px;text-align: left;margin-top:20px">www.jusehezi.com</div>
        </div>
      </div>
      <div class="hellow_img5_GF">
        <img  src="@/assets/photo_17.png" alt />
        <div style="margin-top:10px">【橘色盒子官方】</div>
      </div>
      <div class="About_m">
        <div class="abot_me" @click="toLink2('/Aboutme')">关于我们</div>
        <div class="abot_me" @click="toLink3('/Heart')">核心项目</div>
        <div class="abot_me" @click="toLink4('/Industry')">商业模式</div>
        <div class="abot_me" @click="toLink5('/future')">企业未来</div>
      </div>
      <div class="hellow_img5_footer">© 2019-2024 橘色盒子(重庆橘色盒子科技有限公司)版权所有 - <a class="a" href="https://beian.miit.gov.cn/"
                            target="_blank"
                            >渝ICP备19012992号-1</a></div>
    </div>
  </div>
</template>

<script>
import Head from "./components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {
        selectedContent: 0, // 默认选中第一个内容
    contentList: [
      {
        title: '情趣垂直电商(C端用户)',
        bonContent: {
          title: '情趣垂直电商（C端用户）',
          description: '通过橘色盒子酒店客房情趣用品无人售货机精准流量导入私域留存形成长期服务',
          images: [

            'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/f571c202405231756032435.png'
          ],
          imagesS: [
            'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/6dffa202405241347063655.jpg',
          ]
        }
      },
      {
        title: '情趣用品供应链（B端用户）',
        bonContent: {
            title: '情趣用品供应链（B端用户）',
          description: '情趣用品规模优势自建垂直供应链商城（情趣用品1688）',
          images: [

            'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/ecba3202405231759209301.png'
          ],
          imagesS: [
            'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/3416d202405241352271708.jpg',
          ]
        }
      },
      {
        title: '橘色力量男性提升课程',
        bonContent: {
            title: '橘色力量男性提升课程',
          description: '使用男性延时产品的用户，针对性的提供产品及课程服务帮助提升能力',
          images: [
      
            'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/b7130202405231759546293.png'
          ],
          imagesS: [
            'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/6932f202405241355345044.jpg',
          ]
        }
      },
      {
        title: '酒店供应链',
        bonContent: {
            title: '酒店供应链',
          description: '围绕已服务酒店的规模优势，为酒店挑选更优的酒店供应链服务',
          images: [
      
            'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/28cf9202405231800135823.png'
          ],
          imagesS: [
            'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/6f4c1202405241406401996.jpg',
          ]
        }
      },
      {
        title: '多场景项目开拓',
        bonContent: {
            title: '多场景项目开拓',
          description: '饮酒场景铺设解酒产品无人售货机\n高速路服务区铺设醒困产品无人售货机\n女性公共厕所铺设卫生巾无人售货机',
          images: [

            'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/184c9202405231800329649.png'
          ],
          imagesS: [
            'https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/764df202405241354357696.jpg',
          ]
        }
      }
    ]
    };
  },

  methods: {
    toLink2() {
            this.$router.push({ path: '/Aboutme' })
        },
        toLink3() {
            this.$router.push({ path: '/Heart' })
        },
        toLink4() {
            this.$router.push({ path: '/Industry' })
        },
        toLink5() {
            this.$router.push({ path: '/future' })
        },
  }
};
</script>

<style>
.a{
  color: silver;
}
.a:hover {
    color: orange;
}
pre {
  white-space: pre-line;
  font-family: HarmonyOS_Sans_SC_Regular;

}
.hellow_img5_03 img{
  width: 28px;
  height: 28px;
}
.hellow_img5_GF img{
width: 157px;
height: 157px;
}
.xian5{
  width: 4px;
  height: 58px;
  background: rgba(217, 217, 217, 1);
  position: absolute;
  top: 14%;
  left: 79.2%;
}
.xian4{
  width: 4px;
  height: 58px;
  background: rgba(217, 217, 217, 1);
  position: absolute;
  top: 14%;
  left: 64%;
}
.xian3{
  width: 4px;
  height: 78px;
  background: rgba(217, 217, 217, 1);
  position: absolute;
  top: 12%;
  left: 49.5%;
}
.xian2{
  width: 4px;
  height: 58px;
  background: rgba(217, 217, 217, 1);
  position: absolute;
  top: 14%;
  left: 35.5%;
}
.xian1{
  width: 4px;
  height: 58px;
  background: rgba(217, 217, 217, 1);
  position: absolute;
  top: 14%;
  left: 21%;
}
.xian_1{
  width: 1110px;
  height: 4px;
  background: rgba(217, 217, 217, 1);
  position: absolute;
  top: 14%;
  left: 21%;
}
  .active {
    background-color: rgba(244, 110, 27, 1)!important;; /* 设置选中的背景颜色为红色 */
    color: #fff;
  }

.Hotelscontent_box_bonleft img:first-of-type{
  /* 添加您需要的样式属性 */    width: 230px;
    height: 100px;
    position: absolute;
    left: 36%;
    bottom: 0;
}

.Hotelscontent_box_bonleft1{
font-size: 30px;
font-weight: 900;
padding: 40px;
line-height: 50px;
}
.Hotelscontent_box_bonright{
    /* background: blue; */
    width: 50%;
    height: 700px;
    position: absolute;
    right: 0;
    top: 0;
}
.Hotelscontent_box_bonright img{
width: 324px;
/* height: 560px; */
position: absolute;
left: 20%;
top: 5%;
}
.Hotelscontent_box_bonleft{
    width: 650px;
    height: 570px;
    /* background: red; */
    padding: 100px 50px 30px 40px;
    display: flow;
    text-align: left;
}
.Hotelscontent_box_bon{
    width: 70.5%;
    height: 600px;
    background: #fff;
    position: absolute;
    left: 14.5%;
    top: 32%;

}
.Hotelscontent_box{
    position: absolute;
    left: 13%;
    top: 20%;
    display: flex;
}
.Hotelscontent1{
    width: 244px;
    height: 103px;
    background: #fff;
    margin-left: 30px;
    line-height: 103px;
    cursor: pointer;
}
.Hotelscontent{
    position: absolute;
    left: 45.5%;
    top: 5%;
    font-size: 40px;
font-weight: 700;
color: rgba(244, 110, 27, 1);
}

/* 底部 */
.About_m {
  display: flex;
  position: absolute;
  left: 35%;
  top: 76%;
}
.abot_me {
  color: #fff;
  padding: 0 40px 0 40px;
  border-right: 1px solid #fff;
}
.abot_me:nth-child(4) {
  border-right: none;
}
.hellow_img5_content {
  position: relative;
  height: 500px;
  width: 100%;

  background: rgba(34, 34, 34, 1);
}
.hellow_img5_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: #000;
  line-height: 80px;
  color: silver;
  font-size: 18px;
}
.hellow_img5_01 {
  position: absolute;
  color: #fff;
  left: 20%;
  top: 10%;
}
.hellow_img5_mido {
  position: absolute;
  color: #fff;
  left: 50%;
  top: 10%;
}
.hellow_img5_02 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 20px;
  text-align: left;
}
.hellow_img5_03 {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.hellow_img5_GF {
  position: absolute;
  right: 20%;
  top: 14%;
  color: #fff;
}
.content_1{
  position: absolute;
  /* left: 20%;
  top: 60%; */
  opacity: 0;
  animation: slideIn 2s forwards;
}
@keyframes slideIn {
    from {
        opacity: 0;
        bottom: 0%;
        left: 22%;

    }
    to {
        opacity: 1;
        left: 20%;
        top: 60%;
        
    }
}
/* ===================================================================== */

</style>