<template>
  <div class="content" :class="{ 'scrolled': isScrolled }">
  <img ref="logo" class="content_logo" alt="Vue logo" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/974c0202405221044188383.png">
  <div class="content_tit">
    <div class="content_tit_1"  @click="toLink1('/')">首页 </div>
    <div class="content_tit_1" @click="toLink2('/Aboutme')">关于我们</div>
    <div class="content_tit_1" @click="toLink3('/Heart')">核心项目</div>
    <div class="content_tit_1" @click="toLink4('/Industry')">商业模式</div>
    <div class="content_tit_1" @click="toLink5('/future')">企业未来</div>
  </div>
</div>

</template>

<script>
export default {
    name: 'Head',
  props: {
    msg: String
  },
  data() {
  return {
    isScrolled: false
  };
},

  mounted() {
  window.addEventListener('scroll', this.handleScroll);
},
methods: {
  toLink(tab, path) {
    // 处理页面跳转的逻辑
    // ...
    this.activeTab = tab;
  },
  toLink1() {
            this.$router.push({ path: '/' })
        },
        toLink2() {
            this.$router.push({ path: '/Aboutme' })
        },
        toLink3() {
            this.$router.push({ path: '/Heart' })
        },
        toLink4() {
            this.$router.push({ path: '/Industry' })
        },
        toLink5() {
            this.$router.push({ path: '/future' })
        },
        handleScroll() {
    var that = this;
    if (window.scrollY > 0) {
        // 添加顶部导航栏悬浮时的样式
        that.isScrolled = true;
        if (that.$refs.logo) {
            that.$refs.logo.src = "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/466f7202405221044435406.png"; // 修改为滚动时的图片路径
        }
    } else {
        that.isScrolled = false;
        if (that.$refs.logo) {
            that.$refs.logo.src = "https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/974c0202405221044188383.png"; // 恢复为初始图片路径
        }
    }
}

}

}
</script>

<style>
.content{
  width: 100%;
  height: 80px;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 999;

}
.content_logo{
  width: 202px;
  height: 57px;
  margin-left: 20%;

}
.content_tit{
  font-size: 20px;
  color:#fff;
  display: flex
}
.content_tit_1{
  position: relative;
  margin-left: 100px;
  
}


.content_tit_1:hover::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 2px;
    background-color: orange;
 
}

.content_tit_1:hover {
    font-weight: bold;
    cursor: pointer;
}
.scrolled {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeInOut 2s ease-in-out;
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
 
}

.scrolled .content_tit_1 {
  color: black;
}

</style>