<template>
  <div id="app">
    <Head/>
    <router-view />
  </div>
</template>

<script>

import HelloWorld from './components/HelloWorld.vue'
import Head from './components/Head.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    Head
  }
}
</script>

<style>
#app {
  font-family: HarmonyOS_Sans_SC_Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

}
</style>
