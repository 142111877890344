//当前项目的路由模块
 
import Vue from 'vue'
import VueRouter from 'vue-router'
import HelloWorld from '../components/HelloWorld'
import Aboutme from '../../src/Aboutme'
import Heart from '../../src/Heart'
import Industry from '../../src/Industry'
import future from '../../src/future'


 
//调用Vue.use()把VueRouter安装为Vue的插件
Vue.use(VueRouter)
 
//创建路由的实例对象
const router = new VueRouter({
    routes: [
        {
          path: '/',
          name: 'home',
          component: HelloWorld
        },
        ,
        {
            path:'/Aboutme',
            component:Aboutme
        }
        ,
        {
            path:'/Heart',
            component:Heart
        }     ,
        {
            path:'/Industry',
            component:Industry
        },
        {
            path:'/future',
            component:future
        }

      ],
      mode: 'history',
      base: process.env.BASE_URL,
      scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
      }
})
 
//向外共享的实例对象
export default router