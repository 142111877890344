<template>
  <div>
    <!-- 顶部 -->
    <Head />
    <div class="cente_1">
      <img style="width:100%" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/5a4fc202405241517432365.png" alt />
      <div class="content_1">
        <div class="content_us">商业模式</div>
        <div class="content_yu">BUSINESS MODEL</div>
      </div>
    </div>
    <!-- 为酒店 -->
    <div class="Hotels">
      <img style="width:100%" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/400c0202405231154328180.png" alt />
      <div class="Hotels_box">
        <div class="Hotels_1">我们为酒店解决什么？</div>
        <div class="Hotels_2">WHAT DO WE SOLVE FOR THE HOTEL？</div>
      </div>
       <div class="Hotels_box_img">
        <div class="Hotels_box_img_bacground"  >
            <img class="Hotels_box_img_1" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/e77ad20240523135415584.png" alt="">
            <div class="Hotels_box_img_1_box">
            <img class="Hotels_box_img_2" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/cd4a6202405241151457327.png" alt="">
            <div class="Hotels_box_text1">保护隐私</div>
            </div>
            <div class="Hotels_box_text2">住客购买情趣用品无需前台结算，避免尴尬</div>
        </div> 
        <div class="Hotels_box_img_bacground"  >
            <img class="Hotels_box_img_1" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/1113b202405231409351513.png" alt="">
            <div class="Hotels_box_img_1_box">
            <img class="Hotels_box_img_2" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/b84df202405241150079957.png" alt="">
            <div class="Hotels_box_text1">解决压货</div>
            </div>
            <div class="Hotels_box_text2">实时线上仓库，按计划货避免压货问题</div>
        </div>
        <div class="Hotels_box_img_bacground"  >
            <img class="Hotels_box_img_1" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/bd698202405231410144698.png" alt="">
            <div class="Hotels_box_img_1_box">
            <img class="Hotels_box_img_2" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/6431e202405241151194001.png" alt="">
            <div class="Hotels_box_text1">增加回头客</div>
            </div>
            <div class="Hotels_box_text2">及时为房客提供急需品，提升酒店服务，增加回头客</div>
        </div> 
       </div>
       <div class="Hotels_box_imgimg">
        <div class="Hotels_box_img_bacground"  >
            <img class="Hotels_box_img_1" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/92dfd202405231410556963.png" alt="">
            <div class="Hotels_box_img_1_box">
            <img class="Hotels_box_img_2" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/7e95c202405241152243261.png" alt="">
            <div class="Hotels_box_text1">降低成本</div>
            </div>
            <div class="Hotels_box_text2">降低酒店查房人工成本以及酒店前台服务压力</div>
        </div> 
        <div class="Hotels_box_img_bacground"  >
            <img class="Hotels_box_img_1" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/7bda5202405231411207435.png" alt="">
            <div class="Hotels_box_img_1_box">
            <img class="Hotels_box_img_2" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/94073202405241153043075.png" alt="">
            <div class="Hotels_box_text1">增加收益</div>
            </div>
            <div class="Hotels_box_text2">免费为酒店客房安装智能售货机“互赢”分成模式提高酒店收益</div>
        </div> 
        <div class="Hotels_box_img_bacground"  >
            <img class="Hotels_box_img_1" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/b771e202405231411461692.png" alt="">
            <div class="Hotels_box_img_1_box">
            <img class="Hotels_box_img_2" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/f89a6202405241154042078.png" alt="">
            <div class="Hotels_box_text1">提升服务</div>
            </div>
            <div class="Hotels_box_text2">手机页面选购商品提升住客购买欲</div>
        </div> 
       </div>
    </div>
    <!-- 商业模式 -->
    <div class="mode" >
        <div class="mode_tit">商业模式</div>
        <div class="mode_eng">BUSINESS MODEL</div>
        <div class="mode_box">
        <div class="mode_box_1">
            <img class="mode_box_img1" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/ec5d7202405231544425291.png" alt="">
            <div class="mode_box_bom">
            <img class="mode_box_img2" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/58d5b20240523154933714.png" alt="">
            <div class="mode_box_text1">酒店只需提供场地</div>
            <div class="mode_box_text2">酒店只需提供设备所需场地，0成本投入</div>
            </div>
         
        </div>
        <div class="mode_box_1">
            <img class="mode_box_img1" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/1bc0f202405231607402759.png" alt="">
            <div class="mode_box_bom" style="position: absolute;left: 10%;">
            <img class="mode_box_img2" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/cd3c6202405231612277844.png" alt="">
            <div class="mode_box_text1" style="margin-top:10px">公司提供技术支持<br>品质服务</div>
            <div class="mode_box_text2" style="margin-top:30px">线上功能完整收益可视化<br>专业客服帮助提高酒店收入和客人好评<br>提升入住体验<br>增加潜在入住率和复住率</div>
            </div>
         
        </div>
        <div class="mode_box_1">
            <img class="mode_box_img1" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/18674202405231613262773.png" alt="">
            <div class="mode_box_bom" style="    position: absolute;left: 17%;">
            <img class="mode_box_img2" src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/ad39e202405231614281865.png" alt="">
            <div class="mode_box_text1">增加酒店额外收益</div>
            <div class="mode_box_text2">商品成功售卖，酒店分成比例高</div>
            </div>
         
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="hellow_img5_content">
      <div class="hellow_img5_01">
        <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/a512320240528094722275.png" alt />
          <div style="margin-left:10px">服务热线</div>
        </div>
        <div class="hellow_img5_02">400-023-0001</div>
        <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/060ed202405280946206103.png" alt />
          <div style="margin-left:10px">公司地址</div>
        </div>
        <div style="width:300px;text-align: left;margin-top:20px">重庆市江北区北滨一路186号珠江国际A1写字楼橘色盒子总部13层</div>
      </div>
      <div class="hellow_img5_mido">
        <div class="hellow_img5_03">
          <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/7c43d20240528094657182.png" alt />
          <div style="margin-left:10px">企业邮箱</div>
        </div>
        <div style="width:300px;text-align: left;margin-top:20px">bd@jusehezi.com</div>
        <div style="margin-top:60px">
          <div class="hellow_img5_03">
            <img src="https://jshz-hotel.ks3-cn-beijing.ksyuncs.com/30426202405280940261584.png" alt />
            <div style="margin-left:10px">网址</div>
          </div>
          <div style="width:300px;text-align: left;margin-top:20px">www.jusehezi.com</div>
        </div>
      </div>
      <div class="hellow_img5_GF">
        <img  src="@/assets/photo_17.png" alt />
        <div style="margin-top:10px">【橘色盒子官方】</div>
      </div>
      <div class="About_m">
        <div class="abot_me" @click="toLink2('/Aboutme')">关于我们</div>
        <div class="abot_me" @click="toLink3('/Heart')">核心项目</div>
        <div class="abot_me" @click="toLink4('/Industry')">商业模式</div>
        <div class="abot_me" @click="toLink5('/future')">企业未来</div>
      </div>
      <div class="hellow_img5_footer">© 2019-2024 橘色盒子(重庆橘色盒子科技有限公司)版权所有 - <a class="a" href="https://beian.miit.gov.cn/"
                            target="_blank"
                            >渝ICP备19012992号-1</a></div>
    </div>
  </div>
</template>

<script>
import Head from "./components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {
      currentPage: 1
    };
  },
mounted() {
    let lastScrollTop = 0;
window.addEventListener("scroll", function() {
  let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
  if (currentScroll > lastScrollTop) {
    console.log(currentScroll > lastScrollTop,"currentScroll > lastScrollTop")
    // 鼠标向下滚动
    // 在这里触发你的代码逻辑
    const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5 // 元素可见性的阈值，这里设置为50%
  };

  const callback = (entries, observer) => {
    entries.forEach(entry => {
     
      if (entry.isIntersecting) {
        // 元素进入可视区域
        entry.target.style.transition = "opacity 0.5s, transform 0.5s"; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 1; // 设置元素的透明度为1，实现淡入效果
        entry.target.style.transform = "translateY(0)"; // 从下往上出现
      } else {
        // 元素离开可视区域
        entry.target.style.transition = "opacity 0.5s, transform 0.5s"; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 0; // 设置元素的透明度为0，实现淡出效果
        entry.target.style.transform = "translateY(20px)"; // 元素向上移动隐藏
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  const targets = document.querySelectorAll('.Hotels_box,.Hotels_box_img,.Hotels_box_imgimg,.mode_tit,.mode_eng,.mode_box_1'); // 选择你想观察的标签的类名或选择器

  targets.forEach(target => observer.observe(target));

  } else {
    // 鼠标向上滚动
    // 在这里不触发你的代码逻辑
    console.log(123)
    const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5 // 元素可见性的阈值，这里设置为50%
  };

  const callback = (entries, observer) => {
    entries.forEach(entry => {
     
      if (entry.isIntersecting) {
        // 元素进入可视区域
        entry.target.style.transition = " "; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 1; // 设置元素的透明度为1，实现淡入效果
        entry.target.style.transform = ""; // 从下往上出现
      } else {
        // 元素离开可视区域
        entry.target.style.transition = ","; // 添加淡入淡出和从下往上出现的过渡效果
        entry.target.style.opacity = 1; // 设置元素的透明度为0，实现淡出效果
        entry.target.style.transform = ""; // 元素向上移动隐藏
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  const targets = document.querySelectorAll('.Hotels_box,.Hotels_box_img,.Hotels_box_imgimg,.mode_tit,.mode_eng,.mode_box_1'); // 选择你想观察的标签的类名或选择器

  targets.forEach(target => observer.observe(target));

  }
  lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // 当滚动到顶部时重置滚动位置
}, false);

 
},
  methods: {

    toLink2() {
            this.$router.push({ path: '/Aboutme' })
        },
        toLink3() {
            this.$router.push({ path: '/Heart' })
        },
        toLink4() {
            this.$router.push({ path: '/Industry' })
        },
        toLink5() {
            this.$router.push({ path: '/future' })
        },
  }
};
</script>

<style>
.a{
  color: silver;
}
.a:hover {
    color: orange;
}
.hellow_img5_03 img{
  width: 28px;
  height: 28px;
}
.hellow_img5_GF img{
width: 157px;
height: 157px;
}
.mode_box{
    display: flex;
    justify-content: space-around;
    width: 72%;
    margin: 40px auto;
}
.mode_box_bom{
    position: absolute;
    left: 10%;
    top: 10%;
}
.mode_box_text1{
    font-size: 30px;
font-weight: 700;
margin-top: 60px;
}
.mode_box_text2{
    font-size: 20px;
font-weight: 400;
margin-top: 60px;

}
.mode_box_1{
    position: relative;
    display: grid;
}
.mode_box_img1{
    position: relative;
    width: 440px;
    height: 484px;

    
}
.mode_box_img2{
    /* position: absolute; */
    width: 158px;
    height: 158px;
}
.mode_tit{
font-size: 40px;
font-weight: 700;
margin-top: 40px;
}
.mode_eng{
    font-size: 20px;
font-weight: 400;
margin-top: 10px;
}
.Hotels_box_img_bacground{
    position: relative;
    margin-left: 40px;
    
}
.Hotels_box_img_bacground::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 119, 51, 0.8) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.Hotels_box_img_bacground:hover::before {
    opacity: 1;
}
.Hotels_box_img_1_box{
    position: absolute;
    left: 35%;
    top: 20%;  
}
.Hotels_box_text1{
    font-size: 30px;
font-weight: 700;
color: #fff;
margin: 20px 0 20px 0;
}
.Hotels_box_text2{
 font-size: 22px;
font-weight: 400;
color: #fff;
position: absolute;
left: 0;
bottom: 0;
padding: 40px;
display: none;
}
.Hotels_box_img_bacground:hover .Hotels_box_text2 {
  display: block;
}
.Hotels_box_img{
    position: absolute;
    left: 15%;
    top: 13%;  
    display: flex;
}
.Hotels_box_imgimg{
    position: absolute;
    left: 15%;
    top: 56%;  
    display: flex;
}
.Hotels_box_img_2{
margin-top: 30%;
width: 91px;
height: 91px;
}
.Hotels_box_img_1{
width: 380px;
height: 400px;

}
.Hotels_1{
    font-size: 40px;
font-weight: 700;
}
.Hotels_2{
    font-size: 20px;
font-weight: 400;
margin-top: 10px;
}
.Hotels{
    position: relative;
}
.Hotels_box{
    position: absolute;
    left: 39%;
    top: 2%;
}



/* 底部 */
.About_m {
  display: flex;
  position: absolute;
  left: 35%;
  top: 76%;
}
.abot_me {
  color: #fff;
  padding: 0 40px 0 40px;
  border-right: 1px solid #fff;
}
.abot_me:nth-child(4) {
  border-right: none;
}
.hellow_img5_content {
  position: relative;
  height: 500px;
  width: 100%;

  background: rgba(34, 34, 34, 1);
}
.hellow_img5_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: #000;
  line-height: 80px;
  color: silver;
  font-size: 18px;
}
.hellow_img5_01 {
  position: absolute;
  color: #fff;
  left: 20%;
  top: 10%;
}
.hellow_img5_mido {
  position: absolute;
  color: #fff;
  left: 50%;
  top: 10%;
}
.hellow_img5_02 {
  font-size: 40px;
  font-weight: 700;
  margin-top: 20px;
  text-align: left;
}
.hellow_img5_03 {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.hellow_img5_GF {
  position: absolute;
  right: 20%;
  top: 14%;
  color: #fff;
}
/* ===================================================================== */
.people_content_background {
  width: 200px;
  height: 600px;
  background: #ff7733;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.people_content_Y {
  display: flex;
  align-items: center;
  position: absolute;
  right: 30%;
  top: 65%;
}
.people_content_R {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0%;
  top: 7%;
}
.people_content_X {
  display: flex;
  align-items: center;
  position: absolute;
  left: 2%;
  top: 7%;
}
.people_content_xian {
  width: 30px;
  height: 1px;
  background: rgba(51, 51, 51, 1);
}
.people_img_4 {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.people_img_2 {
  width: 60%;
  position: absolute;
  left: 19.7%;
  top: 5%;
}
.people_img_2 .people_img {
  margin-left: 10px;
}
.people_content_modo {
  width: 72%;
  height: 80%;
  /* background: blue; */
  position: absolute;
  left: 13%;
  top: 10%;
}
.people_img_1 {
  display: grid;
  position: absolute;
  left: 0;
  top: 10%;
}
.people_img_3 {
  display: grid;
  position: absolute;
  right: 0;
  top: 10%;
}
.people_img {
  width: 332px;
  height: 226px;
  margin-top: 10px;
}
.people_img_last {
  width: 650px;
  height: 240px;
  margin-top: 10px;
}
.people_content {
  margin-top: 10px;
}
.people {
  width: 100%;
  height: 1000px;
  position: relative;
  /* background: red; */
}
.carousel {
  width: 100%;
  overflow: hidden;
}

.track {
  display: flex;
  animation-timing-function: linear;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slide {
  flex: 0 0 auto;
  width: 20%; /* 假设展示5张图片，每张占20%宽度 */
}
.slideS {
  flex: 0 0 auto;
  width: 25%; /* 假设展示5张图片，每张占20%宽度 */
}
.qyhonor_tit_box_imgSS {
  position: absolute;
  left: 10%;
  top: 72%;
  width: 80%;
}
.qyhonor_tit_box_img_S {
  width: 310;
  height: 214px;
}
.qyhonor_tit_box_imgS {
  position: absolute;
  left: 10%;
  top: 35%;
  width: 80%;
}
.qyhonor_tit_box_img {
  width: 250px;
  height: 334px;
}
.qyhonor_tit_box {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 41px;
  color: rgba(51, 51, 51, 1);
  margin-top: 20px;
}
.qyhonor_tit {
  position: absolute;
  left: 36%;
  top: 10%;
}
.qyhonor {
  position: relative;
}
.Team_content_box {
  flex: 1;
  text-align: center;
  margin-top: 30px;
}
.Team_content_box_1 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}
.Team_content_box_2 {
  font-size: 20px;
  font-weight: 400;
}
.Team_content {
  width: 70%;
  /* background: red; */
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.Team_box {
  position: absolute;
  left: 44%;
  top: 30%;
}
.Team {
  position: relative;
}
.Team_img {
  width: 100%;
}

.enterprise_img {
  position: absolute;
  right: 10%;
  top: 10%;
  z-index: 1;
}

.enterprise_box_color_u {
  border-bottom: 1px solid rgba(244, 110, 27, 1);
}
.enterprise_box_tit02_content {
  display: flex;
  justify-content: space-between;
  width: 38%;
}
.enterprise_box_tit02_box {
  margin-top: 60px;
}
.enterprise_box_color_b {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
  line-height: 30px;
}
.enterprise_box_color {
  font-size: 40px;
  font-weight: 500;
  color: rgba(255, 141, 26, 1);
}
.enterprise_box_tit02_tit {
  font-weight: 900;
  font-size: 30px;
}
.enterprise_boxit {
  display: flex;
  justify-content: space-between;
  width: 20%;
  margin-top: 100px;
}
.enterprise_boxit div {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}
.enterprise_box_tit01 {
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
}
.enterprise_box_tit {
  font-size: 40px;
  font-weight: 700;
}
img {
  vertical-align: top;
}

.enterprise {
  position: relative;
}
.enterprise_box {
  width: 80%;
  height: 800px;
  /* background: red; */
  position: absolute;
  left: 15%;
  top: 10%;
  text-align: left;
}
.content_yu {
  font-size: 100px;
  font-weight: 900;

  color: rgba(255, 255, 255, 1);
}
.content_us {
  font-size: 60px;
  font-weight: 500;

  color: rgba(255, 255, 255, 1);
  text-align: left;
}
.cente_1 {
  position: relative;
}
.content_1{
  position: absolute;
  /* left: 20%;
  top: 60%; */
  opacity: 0;
  animation: slideIn 2s forwards;
}
@keyframes slideIn {
    from {
        opacity: 0;
        bottom: 0%;
        left: 22%;

    }
    to {
        opacity: 1;
        left: 20%;
        top: 60%;
        
    }
}
</style>